import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../../core/form/components/FormInput';
import FormBase from '../../../../core/form/components/FormBase';

import { controls as transferControls } from '../../forms/croppingTransfers';
import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../../core/form/lib/validateForm';
import {
  fetchCroppingTransfer,
  updateCroppingTransfer,
  createCroppingTransfer,
  removeCroppingTransfer,
} from '../../actions/cropping';

import { cloneDeep, omit } from 'lodash';
import { fetchAnimalClasses } from '../../actions/animal_classes';

class CroppingTransferModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(transferControls),
      data: {},
      id: null,
      isNew: false,
      title: 'Transfer',
      filtered_cropping_product_types: []
    };

    this.filterProperties = this.filterProperties.bind(this);
    this.getControlProperties = this.getControlProperties.bind(this);
    this.loadAnimalClasses = this.loadAnimalClasses.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onEnterpriseChange = this.onEnterpriseChange.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      let controls = initControls(cloneDeep(transferControls));
      let updatedState = {
        isNew: true,
        title: 'New Grazing',
        data: {},
        controls,
        id: null,
      };

      // Load associated animal classes by default selected target enterprise
      const { livestockDivisionIds } = this.props;
      const { enterprises } = this.props.enterprises;

      const livestockEnterprises = enterprises?.rows
        ? enterprises.rows.filter((enterprise) =>
            livestockDivisionIds.includes(enterprise.division_id),
          )
        : [];

      if (livestockEnterprises?.length > 0)
        this.loadAnimalClasses(livestockEnterprises[0].division_id);

      // Update state with selected transfer data
      if (this.props.id) {
        const { id } = this.props;
        updatedState = { ...updatedState, id, isNew: false, title: 'Edit Transfer' };

        const data = await this.props.dispatch(fetchCroppingTransfer(id))
        const { division_id } = data.target_enterprise;
        if (division_id) this.loadAnimalClasses(division_id);

        controls = updateControls(controls, data);
        updatedState.controls = controls;
        updatedState.data = data;

        // this.props.dispatch(fetchCroppingTransfer(id)).then((data) => {
        //   const { division_id } = data.target_enterprise;
        //   if (division_id) this.loadAnimalClasses(division_id);

        //   this.setState({ data, controls: updateControls(controls, data) });
        // });
      }

      updatedState.filtered_cropping_product_types = await this.props.filterCroppingProductTypes(controls.source_enterprise_id.value);
      this.setState(updatedState);
    }
  }

  loadAnimalClasses(division_id) {
    this.props.dispatch(fetchAnimalClasses({ division_id, valid: true }));
  }

  async onSave() {
    const { data, controls, isNew } = this.state;

    let saveData = saveControls(controls, data);
    const { isValid, updatedControls } = await validateFormFieldControls(saveData, controls);

    if (isValid) {
      let saveMethod = updateCroppingTransfer;

      if (isNew) {
        saveData = omit(saveData, ['id']);
        saveMethod = createCroppingTransfer;
      }

      const success = await this.props.dispatch(saveMethod(saveData));

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing Transfer permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeCroppingTransfer(data.id));
      if (success) this.onClose(true);
    }
  }

  async onChange(event) {
    const { controls } = this.state;
    const { name, value } = event.target;

    if (name === 'target_enterprise_id') {
      const enterprise = controls[name]?.options?.find((target) => target.id === value);
      this.loadAnimalClasses(enterprise.division_id);
    }

    this.handleChange(event);
  }

  async onEnterpriseChange(event) {
    const { value: enterprise_id } = event.target;

    this.handleChange(event);

    const filtered_cropping_product_types = await this.props.filterCroppingProductTypes(enterprise_id);
    this.setState({filtered_cropping_product_types});
  }

  filterProperties(enterprise_id) {
    const { enterprises } = this.props.enterprises;
    const enterprise = enterprises?.rows?.filter(({ id }) => id === enterprise_id)[0] ?? null;

    if (!enterprise || enterprise.allocations.length === 0) return [{ id: null, value: '-' }];

    return enterprise.allocations.map(({ property }) => property);
  }

  getControlProperties(controls) {
    const properties = {};

    ['source', 'target'].forEach((key) => {
      const enterprise_id = controls[`${key}_enterprise_id`]?.value ?? null;

      if (enterprise_id) properties[`${key}_properties`] = this.filterProperties(enterprise_id);
    });

    return properties;
  }

  render() {
    let { controls } = this.state;
    const { isNew, title, filtered_cropping_product_types } = this.state;
    const { division_id, isOpen, livestockDivisionIds } = this.props;
    const { animal_classes } = this.props.animal_classes;
    const { responseMessage } = this.props.cropping;
    const { enterprises } = this.props.enterprises;
    const iconName = 'clipboard-list';

    // Filter options
    const division_enterprises = enterprises?.rows
      ? enterprises.rows.filter((enterprise) => enterprise.division_id === division_id)
      : [];

    const livestock_enterprises = enterprises?.rows
      ? enterprises.rows.filter((enterprise) =>
          livestockDivisionIds.includes(enterprise.division_id),
        )
      : [];

    const { source_properties, target_properties } = this.getControlProperties(controls);

    // Set control options
    const typeOptions = {
      product_id: filtered_cropping_product_types,
      source_enterprise_id: division_enterprises,
      source_property_id: source_properties,
      target_animal_class_id: animal_classes,
      target_enterprise_id: livestock_enterprises,
      target_property_id: target_properties,
    };

    // Update control options
    Object.entries(typeOptions).forEach(
      ([key, options]) => (controls = updateControlOptions(controls, key, options)),
    );

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.onEnterpriseChange} control={controls.source_enterprise_id} />
            {source_properties?.length > 1 && (
              <FormInput handleChange={this.handleChange} control={controls.source_property_id} />
            )}
            <FormInput handleChange={this.handleChange} control={controls.product_id} />
            <FormInput handleChange={this.onChange} control={controls.target_enterprise_id} />
            {target_properties?.length > 1 && (
              <FormInput handleChange={this.handleChange} control={controls.target_property_id} />
            )}
            <FormInput handleChange={this.handleChange} control={controls.target_animal_class_id} />
            <FormInput handleChange={this.handleChange} control={controls.transaction_date} />
            <FormInput handleChange={this.handleChange} control={controls.quantity_kg} />
            <FormInput handleChange={this.handleChange} control={controls.amount} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    animal_classes: store.animal_classes,
    attributes: store.attributes,
    cropping: store.cropping,
    enterprises: store.enterprises,
  };
};

export default connect(mapStoreToProps)(CroppingTransferModal);
